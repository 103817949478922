<template>
  <div>
    <div v-if="fetchData" class="text-center">Updating...</div>
    <div class="row questions-container" v-else>
      <b-col class="no-print" cols="5">
        <div class="closeBtn mb-2 text-left">
          <b-col class="no-print" cols="1">
          <p @click="closeCurrentTab()" class="btn m-0">
            <i class="fas fa-window-close fa-4x text-danger"></i>
          </p>
        </b-col>
        <b-col class="no-print" cols="11">
          <h4 class="mb-2 text-center text-info" style="font-size: 20px; font-weight: 600">
           Set Question for exam: {{ boardexam.name }}, Course:{{course.name }}
          </h4>
        </b-col>
        </div>
        <div
          class="text-center font-weight-bold"
          style="font-size: 16px !important"
        >
          {{ boardexam.name }} Question
        </div>
        <div class="text-center">
          <b-link @click="$bvModal.show('recommendedStepsModel')">
            <b> Recommended steps for creating question paper </b></b-link
          >
          <div style="">
            <b-button
              variant="success"
              @click="$bvModal.show('copyQuestionModel')"
              >Copy question paper</b-button
            >
            <b-button variant="danger" class="ml-2" @click="deleteAllQuestions"
              >Delete all questions</b-button
            >
          </div>
        </div>

        <div
          v-for="(qORg, qgindex) in questionsAndGroupsAndSectionView"
          :key="qgindex"
        >
          <div
            v-if="qORg.type == 'group'"
            class="border-info p-1 my-3 mx-1"
            style="border: 3px solid; border-radius: 8px"
          >
            <div
              class="text-center text-info"
              style="font-size: 14px !important"
            >
              Answer {{ groupObjects[qORg.id].best_of_questions }} out of
              {{ groupObjects[qORg.id].questions.length }}
              questions
              <!-- (score: {{ groupObjects[qORg.id].maximum_mark_of_question }}) -->
              <i
                style="font-size: 12px !important"
                class="fas fa-edit text-primary text-sm btn p-0 mx-1"
                @click="editQuestionGroup(groupObjects[qORg.id].id)"
              ></i>
            </div>

            <div
              v-for="(question, orQindex) in groupObjects[qORg.id].questions"
              :key="orQindex"
            >
              <div
                class="border-secondary my-2 p-1"
                style="border: 2px solid; border-radius: 3px"
              >
                <!-- a question datas  -->
                <question-item
                  :question="question"
                  :deleteQuestion="deleteQuestion"
                  :openEditQuestion="openEditQuestion"
                ></question-item>
                <div class="text-right">
                  <b-button
                    variant="outline-success"
                    size="sm"
                    @click="addNewQuestion(question.id, null, null)"
                    >Add Sub Question</b-button
                  >
                </div>
              </div>
              <div
                v-if="groupObjects[qORg.id].questions.length != orQindex + 1"
                class="m-2 text-center font-weight-bold"
              >
                OR
              </div>
            </div>
            <div class="text-right">
              <b-button
                variant="outline-success"
                size="sm"
                @click="addNewQuestion(null, null, groupObjects[qORg.id].id)"
                >Add OR Question</b-button
              >
            </div>
          </div>
          <div
            v-if="qORg.type == 'question'"
            class="border-secondary m-2 p-1"
            style="border: 2px solid; border-radius: 6px"
          >
            <!-- a question datas  -->
            <question-item
              :question="questionObjects[qORg.id]"
              :deleteQuestion="deleteQuestion"
              :openEditQuestion="openEditQuestion"
            ></question-item>
            <div class="text-right">
              <b-button
                variant="outline-success"
                size="sm"
                @click="addNewQuestion(questionObjects[qORg.id].id, null, null)"
                >Add Sub Question</b-button
              >
              <b-button
                variant="outline-success"
                class="ml-1"
                size="sm"
                @click="addNewQuestion(null, questionObjects[qORg.id].id, null)"
                >Add OR Question</b-button
              >
            </div>
          </div>

          <div
            v-if="qORg.type == 'section'"
            class="border-warning m-1"
            style="border: 2px solid; border-radius: 6px"
          >
            <p
              style="font-size: 15px !important"
              class="m-2 text-center font-weight-bold"
            >
              {{ sectionObjects[qORg.id].si_no }}.{{
                sectionObjects[qORg.id].name
              }}
              <i
                style="font-size: 12px !important"
                class="fas fa-edit text-primary text-sm btn p-0 mx-1"
                @click="openEditSection(qORg.id)"
              ></i>
              <i
                style="font-size: 12px !important"
                class="fas fa-trash-alt text-danger text-sm btn p-0 mx-1"
                @click="deleteSection(qORg.id)"
              ></i>
            </p>
          </div>
        </div>

        <div class="border m-2 p-2 text-center" style="border-radius: 6px">
          <b-button
            variant="success"
            size="sm"
            @click="addNewQuestion(null, null, null)"
            >Add New Question</b-button
          >
          <b-button
            variant="warning"
            class="ml-2"
            size="sm"
            @click="addNewSection()"
            >Add New Section</b-button
          >
        </div>
      </b-col>
      <!-- questionPaperPreview  -->
      <b-col class="" style="background-color: white">
        <div
          class="border m-2 p-2 text-center no-print"
          style="border-radius: 6px"
        >
          <b-button variant="success" size="sm" @click="printQuestionPaper()"
            >Print/ Save Question PDF</b-button
          >
        </div>
        <questionPaperPreview
          v-if="enableQuestionView"
          :class="[enableQuestionView ? '' : 'no-print']"
          :questionsAndGroupsAndSectionView="questionsAndGroupsAndSectionView"
          :groupObjects="groupObjects"
          :exam="boardexam"
          :questionObjects="questionObjects"
          :sectionObjects="sectionObjects"
          :courseId="this.$route.params.course_id"
        />

        <div
          class="border m-2 p-2 text-center no-print"
          style="border-radius: 6px"
        >
          <b-button variant="success" size="sm" @click="printQuestionPaper()"
            >Print/ Save Question PDF</b-button
          >
        </div>

        <div class="border border-info my-5 no-print"></div>

        <b-checkbox v-model="enableEvaluationScheme" class="no-print mb-3"
          >View Scheme of Evaluation</b-checkbox
        >

        <div
          class="border m-2 p-2 text-center no-print"
          style="border-radius: 6px"
          v-if="enableEvaluationScheme"
        >
          <b-button
            variant="success"
            size="sm"
            @click="printQuestionEvaluationScheme()"
            >Print/ Save Evaluation Scheme PDF</b-button
          >
        </div>
       <questionPaperEvaluationSchemePreview
          v-if="enableEvaluationScheme"
          :class="[enableEvaluationScheme ? '' : 'no-print']"
          :questionsAndGroupsAndSectionView="questionsAndGroupsAndSectionView"
          :groupObjects="groupObjects"
          :exam="boardexam"
          :courseId="this.$route.params.course_id"
          :questionObjects="questionObjects"
          :sectionObjects="sectionObjects"
        />

        <div
          class="border m-2 p-2 text-center no-print"
          style="border-radius: 6px"
          v-if="enableEvaluationScheme"
        >
          <b-button
            variant="success"
            size="sm"
            @click="printQuestionEvaluationScheme()"
            >Print/ Save Evaluation Scheme PDF</b-button
          >
        </div>
      </b-col>
    </div>

    <b-modal
      class="no-print"
      id="editOrQuestionGroupModel"
      no-close-on-backdrop
      no-close-on-keyboard
      no-close-on-esc
      hide-footer
      centered
      lazy
      size="sm"
    >
      <div>
        <h3 class="text-center">
          Edit Or Question Group {{ editOrQuestionGroup.summary }}
        </h3>

        <b-col>
          <!-- <div>
            <b-form-group label="Enter Group Name:" label-for="input-2" description="">
              <b-form-input id="input-2" v-model="editOrQuestionGroup.summary" type="text"
                placeholder="eg: Group 1 (Answer 3 questions out of 5)"></b-form-input>
              <span class="text text-info">This is just a name for a group of OR questions to be identified
                easily</span>
            </b-form-group>
          </div> -->
          <div>
            <b-form-group
              label="Minimum OR questions for full score:"
              label-for="input-1"
              description=""
            >
              <b-form-input
                id="input-1"
                v-model="editOrQuestionGroup.best_of_questions"
                type="text"
                placeholder="eg: 3"
                required
              ></b-form-input>
              <span class="text text-info"
                >This is the minimum number of OR questions a student must
                answer correctly to attain full score</span
              >
            </b-form-group>
          </div>
          <!-- <div>
            <b-form-group label="Maximum score for a single question:" label-for="input-3" description="">
              <b-form-input id="input-3" v-model="editOrQuestionGroup.maximum_mark_of_question" type="text"
                placeholder="eg: 10" required></b-form-input>
            </b-form-group>
          </div> -->
        </b-col>

        <b-row>
          <b-col sm="12" style="text-align: right">
            <b-button
              variant="success"
              class="text-white sm"
              size="sm"
              @click="updateEditQuestionGroup"
              >Submit</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal
      class="no-print"
      id="editQuestionModel"
      no-close-on-backdrop
      no-close-on-keyboard
      no-close-on-esc
      hide-footer
      hide-header
      lazy
      size="lg"
    >
      <edit-question-component
        v-if="editQuestion != null"
        :editQuestion="editQuestion"
        :questionId="null"
        :courseId="this.$route.params.course_id"
        :finishEditQuestion="finishEditQuestion"
        :cancelEditQuestion="cancelEditQuestion"
        :offlineExam="boardexam.offline_activity == 1"
      ></edit-question-component>
    </b-modal>

    <b-modal
      class="no-print"
      id="editSectionModel"
      no-close-on-backdrop
      no-close-on-keyboard
      no-close-on-esc
      hide-footer
      centered
      lazy
      size="sm"
    >
      <div>
        <h3 class="text-center">Edit {{ editSection.name }}</h3>
        <b-col>
          <div>
            <b-form-group label="Order No:" label-for="input-2" description="">
              <b-form-input
                id="input-2"
                v-model="editSection.si_no"
                type="text"
                placeholder="eg: 3"
              ></b-form-input>
              <span class="text text-info"></span>
            </b-form-group>
          </div>
          <div>
            <b-form-group
              label="Section Name:"
              label-for="input-1"
              description=""
            >
              <b-form-input
                id="input-1"
                v-model="editSection.name"
                type="text"
                placeholder="eg: Section 1. Each question has maximum 5 marks."
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </b-col>

        <b-row>
          <b-col sm="12" style="text-align: right">
            <b-button
              variant="success"
              class="text-white sm"
              size="sm"
              @click="updateEditSection"
              >Submit</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal class="no-print" id="recommendedStepsModel" hide-footer size="md">
      <h5><b> Step 1: Setting the Question Paper Format</b></h5>
      <p>
        Instructors can set question paper by clicking on 'Create question
        paper' present next to activity name in the activity list. Instructors
        can start by creating the question paper format. This involves creating
        sections, creating question fields for main questions, sub-questions,
        and optional (OR) questions, grouping them as needed. For OR question
        groupings, instructors can specify the minimum number of questions a
        student should answer. Once this format is established, instructors can
        proceed to the next step.
      </p>
      <h5><b>Step 2: Adding Questions and Details</b></h5>
      <p>
        In the second step, by clicking on the 'Edit icon' in each question
        field, instructors can add question numbers, maximum marks, and the
        actual question text. They can also include question text, equations,
        and images as needed. Additionally, instructors have the option to map
        the question to Course Outcomes (CO), Modules, and Blooms Taxonomy
        Levels if required.
      </p>
      <p>
        A live visualization of the actual question paper will be displayed on
        the right side of the screen as questions are entered. This allows
        instructors to preview the question paper's appearance in real-time.
        Once the question paper is complete, it can be printed.
      </p>
    </b-modal>

    <b-modal
      class="no-print"
      id="copyQuestionModel"
      no-close-on-backdrop
      no-close-on-keyboard
      no-close-on-esc
      hide-footer
      centered
      lazy
      size="md"
    >
      <copy-question-component
        :examId="boardexam.id"
        :courseId="this.$route.params.course_id"
        :finishEditQuestion="finishEditQuestion"
      ></copy-question-component>
    </b-modal>
  </div>
</template>

<script>
// import Vue from 'vue';
// import * as MathLive from 'mathlive';
// Vue.use(MathLive);
import QuestionItem from "./manageUniversityExamQuestionsItem.vue"; // Import the QuestionItem component
import EditQuestionComponent from "./manageUniversityExamQuestionsEditQuestion.vue";
import CopyQuestionComponent from "./manageUniversityExamQuestionsCopyQuestion.vue";
import questionPaperPreview from "./questionPaperPreview.vue";
import questionPaperEvaluationSchemePreview from "./questionPaperEvaluationSchemePreview.vue";

export default {
  //props: ["exam",],
  async created() {
    this.getBoardExam();
    this.getCourse();
    // this.cloudStorageRoot = this.$store.getters.getAPIKey.userAttachments;
    this.prepareQuestionsView();
    // MathLive.renderMathInDocument()
  },
  components: {
    QuestionItem, // Register the QuestionItem component
    EditQuestionComponent,
    CopyQuestionComponent,
    questionPaperPreview,
    questionPaperEvaluationSchemePreview,
  },
  data() {
    return {
      boardexam: [],
      // cloudStorageRoot: '',
      fetchData: false,
      orQuestionGroups: [],
      groupObjects: {},
      questions: [],
      questionObjects: {},
      questionsAndGroupsAndSectionView: [],
      course:[],
      editOrQuestionGroup: {},
      editQuestion: null, // null or question object

      sections: [],
      sectionObjects: {},
      editSection: {},
      enableQuestionView: true,
      enableEvaluationScheme: false,
    };
  },
  methods: {
    printQuestionEvaluationScheme() {
      this.enableEvaluationScheme = true;
      this.enableQuestionView = false;
      // disable question paper
      setTimeout(() => {
        window.print();
      }, 200)
      setTimeout(() => {
        this.enableEvaluationScheme = true;
        this.enableQuestionView = true;
      }, 1000)
      // this.$root.subContent.subContentComponent = "ExamQuestionPaperPreview";
      // this.$root.subContent.subContentData = {
      //   questionsAndGroupsAndSectionView: this.questionsAndGroupsAndSectionView,
      //   groupObjects: this.groupObjects, exam: this.exam, questionObjects: this.questionObjects, sectionObjects: this.sectionObjects
      // };
      // this.$root.subContent.enableMainContent = false;
    },
    async deleteSection(sectionId) {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/boardexamsections/${sectionId}`;
      await this.$axios
        .delete(url)
        .then(() => {
          this.prepareQuestionsView();
        })
        .catch((error) => {
          error;
        });

      this.fetchData = false;
    },
    openEditSection(sectionId) {
      this.editSection = this.sectionObjects[sectionId];
      this.$bvModal.show("editSectionModel");
    },
    async updateEditSection() {
      this.$bvModal.hide("editSectionModel");
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/boardexamsections/${this.editSection.id}`;
      await this.$axios
        .put(url, {
          name: this.editSection.name,
          si_no: this.editSection.si_no,
        })
        .then(() => {
          this.prepareQuestionsView();
        })
        .catch((error) => {
          this.$bvModal.show("editSectionModel");
          let errortxt = "";
          if (error.response)
            if (error.response.status == 422) {
              let errormsgs = error.response.data.errors;
              for (const key in errormsgs) {
                errortxt = errormsgs[key][0];
              }
            } else {
              errortxt = error.response.data.message;
            }
          else {
            errortxt = "Failed to Create";
          }
          this.$toast.error(errortxt, {
            position: "top",
            duration: 3000,
          });
          this.fetchData = false;
        });
    },
    async deleteAllQuestions() {
      if (
        confirm(
          "Are you sure you want to delete all questions? Deleting questions will also delete the choices, answer, attachment, score, and the question-co mapping."
        )
      ) {
        this.fetchData = true;
        const url =
          this.$store.getters.getAPIKey.mainAPI +
          `/boardexamquestions/delete?boardexam_id=${this.$route.params.exam_id}&course_id=${this.$route.params.course_id}`;
        await this.$axios
          .delete(url)
          .then(() => {
            this.prepareQuestionsView();
          })
          .catch((error) => {
            error;
          });
        this.fetchData = false;
      }
    },
    async deleteQuestion(questionId) {
      if (
        confirm(
          "Are you sure you want to delete this questions? Deleting questions will also delete the choices, answer, attachment, score, and the question-co,po,pso,module mapping."
        )
      ) {
        this.fetchData = true;
        const url =
          this.$store.getters.getAPIKey.mainAPI +
          `/boardexamquestions/${questionId}`;
        await this.$axios
          .delete(url)
          .then(() => {
            this.prepareQuestionsView();
          })
          .catch((error) => {
            error;
          });

        this.fetchData = false;
      }
    },
    async getBoardExam() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/boardexams/${this.$route.params.exam_id}`;
        
      await this.$axios
        .get(url)
        .then((response) => {
          this.boardexam = response.data;
         // this.course = response.data.course;
        })
        .catch((error) => {
          error;
        });
    },
    async getCourse() {
      let url =
        this.$store.getters.getAPIKey.mainAPI +
        `/courses/${this.$route.params.course_id}`;
      await this.$axios.get(url).then((response) => {
        this.course = response.data;
      });
    },

    async addNewSection() {
      this.fetchData = true;
      let postData = {
        boardexam_id: this.$route.params.exam_id,
        course_id: this.$route.params.course_id,
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/boardexamsections`;
      await this.$axios
        .post(url, postData)
        .then(() => {})
        .catch((error) => {
          this.$toast.error(
            "Failed to add section, Please try again. (" +
              error.response.data.message +
              ")"
          );
        });
      await this.prepareQuestionsView();
      this.fetchData = false;
    },
    async addNewQuestion(subQuestionParentId, orQuestionId, orQuestionGroupId) {
      this.fetchData = true;
      let postData = {
        subquestion_parent_id: subQuestionParentId,
        orquestion_group_id: orQuestionGroupId,
        orquestion_question_id: orQuestionId,
        boardexam_id: this.$route.params.exam_id,
        course_id: this.$route.params.course_id,
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/boardexamquestions`;
      await this.$axios
        .post(url, postData)
        .then(() => {})
        .catch((error) => {
          this.$toast.error(
            "Failed to add question, Please try again. (" +
              error.response.data.message +
              ")"
          );
        });
      await this.prepareQuestionsView();
      this.fetchData = false;
    },
    async editQuestionGroup(groupId) {
      this.editOrQuestionGroup = this.groupObjects[groupId];
      this.$bvModal.show("editOrQuestionGroupModel");
    },
    async updateEditQuestionGroup() {
      this.$bvModal.hide("editOrQuestionGroupModel");
      this.fetchData = true;
      let groupId = this.editOrQuestionGroup.id;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/boardexamorquestiongroups/${this.editOrQuestionGroup.id}`;
      await this.$axios
        .put(url, {
          boardexam_id: this.editOrQuestionGroup.boardexam_id,
          course_id: this.editOrQuestionGroup.course_id,
          best_of_questions: this.editOrQuestionGroup.best_of_questions,
        })
        .then(() => {
          this.groupObjects[groupId].best_of_questions =
            this.editOrQuestionGroup.best_of_questions;
          this.editOrQuestionGroup = {};
        })
        .catch((error) => {
          this.$bvModal.show("editOrQuestionGroupModel");
          let errortxt = "";
          if (error.response)
            if (error.response.status == 422) {
              let errormsgs = error.response.data.errors;
              for (const key in errormsgs) {
                errortxt = errormsgs[key][0];
              }
            } else {
              errortxt = error.response.data.message;
            }
          else {
            errortxt = "Failed to Create";
          }
          this.$toast.error(errortxt, {
            position: "top",
            duration: 3000,
          });
        });
      this.fetchData = false;
    },
    async prepareQuestionsView() {
      this.orQuestionGroups = [];
      this.groupObjects = {};
      this.questions = [];
      this.questionObjects = {};
      this.questionsAndGroupsAndSectionView = [];
      this.sections = [];
      this.sectionObjects = {};

      await Promise.all([
        this.getExamQuestions(),

        this.getExamOrQuestionGroups(),
        this.getExamSections(),
      ]);
      let groupObjects = {};
      this.orQuestionGroups.forEach((group) => {
        group.questions = [];
        groupObjects[group.id] = group;
      });

      // initiate main questions
      this.questions.forEach((question) => {
        if (question.subquestion_parent_id == null) {
          question.subQuestions = [];
        }
        this.questionObjects[question.id] = question;
      });
      // add sub questions
      this.questions.forEach((question) => {
        if (question.subquestion_parent_id != null) {
          this.questionObjects[
            question.subquestion_parent_id
          ].subQuestions.push(question);
        }
      });

      let questionsAndGroupsAndSectionView = [];
      let tempGroupIds = [];
      let tempSectionIds = [];
      this.questions.forEach((question) => {
        if (question.subquestion_parent_id == null) {
          let section = this.questionSectionBySiNo(question.question_no);
          if (section != null) {
            if (!tempSectionIds.includes(section.id))
              questionsAndGroupsAndSectionView.push({
                type: "section",
                id: section.id,
              });
            tempSectionIds.push(section.id);
          }
          if (question.orquestion_group_id == null) {
            questionsAndGroupsAndSectionView.push({
              type: "question",
              id: question.id,
            });
          } else {
            groupObjects[question.orquestion_group_id].questions.push(question);
            if (!tempGroupIds.includes(question.orquestion_group_id)) {
              questionsAndGroupsAndSectionView.push({
                type: "group",
                id: question.orquestion_group_id,
              });
              tempGroupIds.push(question.orquestion_group_id);
            }
          }
        }
      });

      this.sections.forEach((section) => {
        this.sectionObjects[section.id] = section;
        if (!tempSectionIds.includes(section.id)) {
          questionsAndGroupsAndSectionView.push({
            type: "section",
            id: section.id,
          });
        }
      });
      this.questionsAndGroupsAndSectionView = questionsAndGroupsAndSectionView;
      this.groupObjects = groupObjects;
      this.fetchData = false;
    },
    async getExamOrQuestionGroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/boardexamorquestiongroups?boardexam_id=${this.$route.params.exam_id}&course_id=${this.$route.params.course_id}`;
      let orquestiongroups = await this.$axios.get(url).then((response) => {
        return response.data;
      });
      this.orQuestionGroups = orquestiongroups;
    },
    async getExamSections() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/boardexamsections?boardexam_id=${this.$route.params.exam_id}&course_id=${this.$route.params.course_id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          const sections = response.data;
          this.sections = sections;
        })
        .catch((error) => {
          error;
        });
      this.fetchData = false;
    },
    cancelEditQuestion() {
      this.editQuestion = null;
      this.$bvModal.hide("editQuestionModel");
    },
    questionSectionBySiNo(question_no) {
      return this.sections.find((obj) => obj.si_no + "" == question_no + "");
    },
    async openEditQuestion(questionId) {
      this.editQuestion = this.questionObjects[questionId];
      this.$bvModal.show("editQuestionModel");
    },
    async finishEditQuestion() {
      this.$bvModal.hide("editQuestionModel");
      this.$bvModal.hide("copyQuestionModel");
      this.editQuestion = null;
      this.prepareQuestionsView();
    },
    async getExamQuestions() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/boardexamquestions?course=${this.$route.params.course_id}&boardexam_id=${this.$route.params.exam_id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          const questions = response.data;
          this.questions = questions;
        })
        .catch((error) => {
          error;
        });
      this.fetchData = false;
    },
    printQuestionPaper() {
      this.enableEvaluationScheme = false;
      this.enableQuestionView = true;
      // disable evaluation scheme
      setTimeout(() => {
        window.print();
      }, 200);
      setTimeout(() => {
        this.enableEvaluationScheme = true;
        this.enableQuestionView = true;
      }, 1000);

      // this.$root.subContent.subContentComponent = "ExamQuestionPaperPreview";
      // this.$root.subContent.subContentData = {
      //   questionsAndGroupsAndSectionView: this.questionsAndGroupsAndSectionView,
      //   groupObjects: this.groupObjects, exam: this.exam, questionObjects: this.questionObjects, sectionObjects: this.sectionObjects
      // };
      // this.$root.subContent.enableMainContent = false;
    },
  },
};
</script>

<style>
.questions-container button {
  padding: 1px !important;
}
</style>
