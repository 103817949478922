import { render, staticRenderFns } from "./questionPaperPreview.vue?vue&type=template&id=afb1cf72&scoped=true"
import script from "./questionPaperPreview.vue?vue&type=script&lang=js"
export * from "./questionPaperPreview.vue?vue&type=script&lang=js"
import style0 from "./questionPaperPreview.vue?vue&type=style&index=0&id=afb1cf72&prod&scoped=true&lang=css"
import style1 from "./questionPaperPreview.vue?vue&type=style&index=1&id=afb1cf72&prod&lang=css"
import style2 from "./questionPaperPreview.vue?vue&type=style&index=2&id=afb1cf72&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afb1cf72",
  null
  
)

export default component.exports