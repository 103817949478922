<template>
  <div>
    <p> {{ question.question_no }}. (Please click edit icon to edit question)
      {{ question.subQuestions.length > 0 ? 'Score of all sub question' : 'Score' }}({{ questionMaxScore() }})
      <i style="font-size: 12px !important;" class="fas fa-edit text-primary text-sm btn p-0 mx-1"
        @click="openEditQuestion(question.id)"></i>
      <i style="font-size: 12px !important;" class="fas fa-trash-alt text-danger text-sm btn p-0 mx-1"
        @click="deleteQuestion(question.id)"></i>
    </p>
    <div v-if="(question.subQuestions.length > 0)" class="mt-2 mb-2 ml-5">
      <div v-for="(subQuestion, subQindex) in question.subQuestions" :key="subQindex" class="border border-secondary mb-2"
        style=" border-radius: 4px;">
        <p>{{ subQuestion.question_no }}. (Please click edit icon to edit question) Score:({{ subQuestion.maximum_mark }})
          <i style="font-size: 12px !important;" class="fas fa-edit text-primary text-sm btn p-0 mx-1"
            @click="openEditQuestion(subQuestion.id)"></i>
          <i style="font-size: 12px !important;" class="fas fa-trash-alt text-danger text-sm btn p-0 mx-1"
            @click="deleteQuestion(subQuestion.id)"></i>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: Object, // Pass the question data as a prop
    deleteQuestion: Function,
    openEditQuestion: Function,
  },
  methods: {
    questionMaxScore() {
      return this.question.subQuestions.length > 0 ?
        (this.question.subQuestions.reduce((total, obj) => total + Number(obj.maximum_mark) ?? 0, 0))
        : this.question.maximum_mark;
    },
    arrayHasValue(arrayOrNull) {
      if (arrayOrNull !== null && Array.isArray(arrayOrNull) && arrayOrNull.length > 0) return true;
      return false;
    },
  },
};
</script>

<style >
.questions-container button {
  padding: 1px !important;
}
</style>
